<style lang="scss" >
#client-location {
  .table-form-control {
    width: 100px !important;
    float: right;
  }
}
#client-service {
  input[type="file"] {
    display: none;
  }
}
.custom-file-upload {
  padding: 6px 12px;
  cursor: pointer;
  background: #1bc5bd;
  color: white !important;
  border-radius: 3px;
}
.update-rate-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#client-location  .symbol-label{
  width: 120px !important;
}
</style>

<template>
  <div class="row" id="client-location">
    <div class="col-md-8 offset-md-2">
      <div class="card form-body">
        <div class="card-body">
          <form action="">
            <div class="row">
              <div class="col-md-12">
                <legend class="update-rate-header">
                  <h6 v-if="this.$route.name != 'admin.serviceprovider.edit'">
                    Location Information
                  </h6>
                  <h6 v-else>
                    Service Provider Information
                  </h6>
                </legend>

                <hr />
              </div>
            </div>
            <div class="row">
              <div class="col-md-8">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="name"
                      >Code <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="name"
                      id=""
                      disabled
                      class="form-control"
                      v-model="slug"
                      :class="errors['slug'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['slug']" class="text-danger">{{
                      errors["slug"][0]
                    }}</span>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="name"
                      >Name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="name"
                      id=""
                      class="form-control"
                      v-model="formData.name"
                      :class="errors['name'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['name']" class="text-danger">{{
                      errors["name"][0]
                    }}</span>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="description"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea
                      name="description"
                      id=""
                      cols="30"
                      rows="2"
                      class="form-control"
                      v-model="formData.description"
                      :class="
                        errors['description'] ? 'border border-danger' : ''
                      "
                    ></textarea>
                    <span v-if="errors['description']" class="text-danger">{{
                      errors["description"][0]
                    }}</span>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="county">County </label>
                    <select
                      v-model="formData.county"
                      class="form-control"
                      :class="errors['county'] ? 'border border-danger' : ''"
                    >
                      <option value="">Select county</option>

                      <option value="Alachua">Alachua</option>
                      <option value="Baker">
                        Baker
                      </option>
                      <option value="Bay">
                        Bay
                      </option>
                      <option value="Bradford">
                        Bradford
                      </option>
                      <option value="Brevard">
                        Brevard
                      </option>
                      <option value="Broward">
                        Broward
                      </option>

                      <option value="Calhoun">
                        Calhoun
                      </option>
                      <option value="Charlotte">
                        Charlotte
                      </option>
                      <option value="Citrus">
                        Citrus
                      </option>
                      <option value="Clay">
                        Clay
                      </option>
                      <option value="Collier">
                        Collier
                      </option>
                      <option value="Columbia">
                        Columbia
                      </option>
                      <option value="Desoto">
                        Desoto
                      </option>
                      <option value="Dixie">
                        Dixie
                      </option>
                      <option value="Duval">
                        Duval
                      </option>
                      <option value="Escambia">
                        Escambia
                      </option>
                      <option value="Flagler">Flagler</option>
                      <option value="Franklin">
                        Franklin
                      </option>
                      <option value="Gadsden">
                        Gadsden
                      </option>
                      <option value="Georgia">
                        Georgia
                      </option>
                      <option value="Gilchrist">
                        Gilchrist
                      </option>
                      <option value="Glades">
                        Glades
                      </option>
                      <option value="Gulf">
                        Gulf
                      </option>
                      <option value="Hamilton">
                        Hamilton
                      </option>
                      <option value="Hardee">
                        Hardee
                      </option>
                      <option value="Hendry">
                        Hendry
                      </option>
                      <option value="Hernando">
                        Hernando
                      </option>
                      <option value="Highlands">
                        Highlands
                      </option>
                      <option value="Hillsborough">
                        Hillsborough
                      </option>
                      <option value="Holmes">
                        Holmes
                      </option>
                      <option value="Indian River">
                        Indian River
                      </option>
                      <option value="Jackson">
                        Jackson
                      </option>
                      <option value="Jefferson">Jefferson</option>
                      <option value="Lafayette">
                        Lafayette
                      </option>
                      <option value="Lake">
                        Lake
                      </option>
                      <option value="Lee">
                        Lee
                      </option>
                      <option value="Leon">
                        Leon
                      </option>
                      <option value="Levy">
                        Levy
                      </option>
                      <option value="Liberty">
                        Liberty
                      </option>
                      <option value="Madison">
                        Madison
                      </option>
                      <option value="Manatee">
                        Manatee
                      </option>
                      <option value="Marion">
                        Marion
                      </option>
                      <option value="Martin">
                        Martin
                      </option>
                      <option value="Miami‐Dade">
                        Miami‐Dade
                      </option>
                      <option value="Monroe">
                        Monroe
                      </option>
                      <option value="Nassau">
                        Nassau
                      </option>
                      <option value="Okaloosa">
                        Okaloosa
                      </option>
                      <option value="Okeechobee">
                        Okeechobee
                      </option>
                      <option value="Orange">Orange</option>
                      <option value="Osceola">
                        Osceola
                      </option>
                      <option value="Palm Beach">
                        Palm Beach
                      </option>
                      <option value="Pasco">
                        Pasco
                      </option>
                      <option value="Pinellas">
                        Pinellas
                      </option>
                      <option value="Polk">
                        Polk
                      </option>
                      <option value="Putnam">
                        Putnam
                      </option>
                      <option value="Santa Rosa">
                        Santa Rosa
                      </option>
                      <option value="Sarasota ">
                        Sarasota
                      </option>
                      <option value="Seminole">
                        Seminole
                      </option>
                      <option value="St. Johns">
                        St. Johns
                      </option>
                      <option value="St. Lucie">
                        St. Lucie
                      </option>
                      <option value="Sumter">
                        Sumter
                      </option>
                      <option value="Suwannee">
                        Suwannee
                      </option>
                      <option value="Taylor">
                        Taylor
                      </option>
                      <option value="Union">
                        Union
                      </option>
                      <option value="Volusia">Volusia</option>
                      <option value="Wakulla">
                        Wakulla
                      </option>
                      <option value="Walton">
                        Walton
                      </option>
                      <option value="Washington">
                        Washington
                      </option>
                      <option value="Other States">
                        Other States
                      </option>
                    </select>
                    <span v-if="errors['county']" class="text-danger">{{
                      errors["county"][0]
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <h6></h6>
                  <hr />
                  <div class="form-group col-md-6">
                    <label for="address"
                      >Address Line 1 <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="address"
                      class="form-control"
                      v-model="formData.address"
                      :class="errors['address'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['address']" class="text-danger">{{
                      errors["address"][0]
                    }}</span>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="address">Address Line 2 </label>
                    <input
                      type="text"
                      name="address"
                      class="form-control"
                      v-model="formData.address2"
                      :class="errors['address2'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['address2']" class="text-danger">{{
                      errors["address2"][0]
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <h6></h6>
                  <hr />

                  <div class="form-group col-md-6">
                    <label for="longitutde"
                      >State <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="state"
                      class="form-control"
                      v-model="formData.state"
                      :class="errors['state'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['state']" class="text-danger">{{
                      errors["state"][0]
                    }}</span>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="city"
                      >City <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="city"
                      class="form-control"
                      v-model="formData.city_name"
                      :class="errors['city_name'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['city_name']" class="text-danger">{{
                      errors["city_name"][0]
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <h6></h6>
                  <hr />
                  <div class="form-group col-md-6">
                    <label for="zipcode"
                      >Zip Code <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="zipcode"
                      v-mask="'#####'"
                      class="form-control"
                      v-model="formData.zipcode"
                      :class="errors['zipcode'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['zipcode']" class="text-danger">{{
                      errors["zipcode"][0]
                    }}</span>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="client_z"
                      >Timezone<span class="text-danger">*</span></label
                    >
                    <select
                      v-model="formData.client_timezone"
                      class="form-control"
                      :class="
                        errors['client_timezone'] ? 'border border-danger' : ''
                      "
                    >
                      <option value="">Select time-zone</option>

                      <option
                        v-for="(item, index) in timeZones"
                        :key="index"
                        :value="item"
                        >{{ item }}</option
                      >
                    </select>
                    <span
                      v-if="errors['client_timezone']"
                      class="text-danger"
                      >{{ errors["client_timezone"][0] }}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Contact Number</label>
                      <input
                        type="text"
                        v-mask="'(###)-###-####'"
                        class="form-control"
                        v-model="formData.phone_number"
                        name=""
                        id=""
                        :class="
                          errors['phone_number'] ? 'border border-danger' : ''
                        "
                      />
                      <span v-if="errors['phone_number']" class="text-danger">{{
                        errors["phone_number"][0]
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">Show Contact (for email) </label>
                      <input
                        type="text"
                        v-mask="'(###)-###-####'"
                        class="form-control"
                        v-model="formData.show_contact"
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                </div>
                <div
                  class="row"
                  v-if="this.$route.name == 'admin.serviceprovider.edit'"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="exampleFormControlSelect1"
                        >Payment Method</label
                      >
                      <select
                        class="form-control"
                        id="exampleFormControlSelect1"
                        v-model="formData.payment_method"
                        :class="
                          errors['payment_method'] ? 'border border-danger' : ''
                        "
                      >
                        <option value="" selected>Select Payment Method</option>
                        <option value="cardpointe">CardPointe</option>
                        <option value="heartland" selected>Heartland</option>
                      </select>
                      <span
                        v-if="errors['payment_method']"
                        class="text-danger"
                        >{{ errors["payment_method"][0] }}</span
                      >
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <keep-alive>
                      <GoogleMap
                        ref="map"
                        @getFullAddress="setFullAddress"
                        @dragPointer="setLocation"
                        @onSearchClick="setLocation"
                      ></GoogleMap>
                    </keep-alive>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="name"
                        >Notification Email
                        <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        name="name"
                        id=""
                        class="form-control"
                        v-model="formData.notification_email"
                        :class="
                          errors['notification_email']
                            ? 'border border-danger'
                            : ''
                        "
                      />
                      <span
                        v-if="errors['notification_email']"
                        class="text-danger"
                        >{{ errors["notification_email"][0] }}</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="display_name">Display Name </label>
                      <input
                        type="text"
                        name="display_name"
                        id=""
                        class="form-control"
                        v-model="formData.display_name"
                        :class="
                          errors['display_name'] ? 'border border-danger' : ''
                        "
                      />
                      <span v-if="errors['display_name']" class="text-danger">{{
                        errors["display_name"][0]
                      }}</span>
                    </div>
                  </div>
                </div>
                <h6 v-if="this.$route.name == 'admin.serviceprovider.edit'">
                  Support Info
                </h6>
                <div
                  class="form-group"
                  v-if="this.$route.name == 'admin.serviceprovider.edit'"
                >
                  <supporteditor
                    v-model="formData.support_info"
                    api-key="71vg7medn8k1guoqbic2prdchbq0em4dqvhruo5ko352ck6h"
                    :init="{
                      height: 200,
                      menubar: false,
                      plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount'
                      ],
                      toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help'
                    }"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <div class="form-group ">
                  <div class="journey" v-if="showImage">
                    <canvas
                      id="imageCanvas"
                      ref="imageCanvas"
                      style="width: 120px"
                    ></canvas>
                    <!-- <image-crop
                      v-if="currentUser.u.is_admin == 1"
                      :image_base64="image_base64"
                      @base64Img="changeImage"
                    ></image-crop>
                    <image-crop
                      v-else
                      :image_base64="image_base64"
                      :aspectRatioheight="getAspectheight"
                      :aspectRatiowidth="getAspectwidth"
                      :canvasheight="getCanvasheight"
                      :canvaswidth="getCanvasheight"
                      @base64Img="changeImage"
                    ></image-crop> -->
                  </div>
                  <div
                    class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4"
                    v-if="image_place != ''"
                  >
                    <div
                      class="symbol-label"
                      :style="
                        'background-size: contain;background-image: url(' +
                          image_place + 
                          ')'
                      "
                    ></div>
                  </div>

                  <br />

                  <label class="custom-file-upload">
                    <input
                      type="file"
                      id="imageLoader"
                      @change="updateCanvasImage($event, 1)"
                      hidden
                      :class="errors['image'] ? 'border border-danger' : ''"
                    />
                    <i class="fa fa-upload mr-2" style="color:white"></i>Upload
                    Image
                  </label>
                  <span v-if="errors['image']" class="text-danger">{{
                    errors["image"][0]
                  }}</span>
                  <div v-if="!currentUser.u.is_service_provider">
                    <div class="journey" v-if="showIcon">
                      <canvas
                        id="iconcanvas"
                        ref="iconcanvas"
                        style="width: 120px"
                      ></canvas>
                    </div>
                    <div
                      class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4"
                      v-if="image_icon != ''"
                      
                    >
                      <div
                        class="symbol-label"
                        :style="
                          'background-size: contain;background-image: url(' +
                            image_icon + 
                            ')'
                        "
                      ></div>
                    </div>

                    <br />

                    <label class="custom-file-upload">
                      <input
                        type="file"
                        id="imageLoader"
                        @change="updateCanvasImage($event, 2)"
                        hidden
                        :class="errors['image'] ? 'border border-danger' : ''"
                      />
                      <i class="fa fa-upload mr-2" style="color:white"></i
                      >Upload Icon
                    </label>
                  </div>

                  <div class="form-group">
                    <label>Active</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.is_active"
                        class="switch"
                        id="switch-normal"
                      />
                      <label for="switch-normal"></label>
                    </span>
                  </div>
                  <div
                    class="form-group"
                    v-if="this.$route.name == 'admin.serviceprovider.edit'"
                  >
                    <label>Show Store Forward</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.show_store_forward"
                        class="switch"
                        id="switch-store"
                      />
                      <label for="switch-store"></label>
                    </span>
                  </div>
                  <div
                    class="form-group"
                    v-if="this.$route.name != 'admin.serviceprovider.edit'"
                  >
                    <label>On Site Payment</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.enable_on_site_payment"
                        id="switch-on-site-payment"
                      />
                      <label for="switch-on-site-payment"></label>
                    </span>
                  </div>
                  <div
                    class="form-group"
                    v-if="this.$route.name != 'admin.serviceprovider.edit'"
                  >
                    <label>Mobile Location</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.is_mobile_fingerprinting"
                        id="switch-mobile-fingerprinting"
                      />
                      <label for="switch-mobile-fingerprinting"></label>
                    </span>
                  </div>
                  <div
                    class="form-group"
                    v-if="this.$route.name != 'admin.serviceprovider.edit'"
                  >
                    <label>Card Scan</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.card_scan"
                        id="switch-card_scan"
                      />
                      <label for="switch-card_scan"></label>
                    </span>
                  </div>
                  <div
                    class="form-group"
                    v-if="this.$route.name != 'admin.serviceprovider.edit'"
                  >
                    <label>Photo Enabled</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.photo_enabled"
                        id="switch-photo-enabled"
                      />
                      <label for="switch-photo-enabled"></label>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            class="btn btn-success"
            @click="update('kt_update_service')"
            style="float: right"
            ref="kt_update_service"
          >
            <i class="fa fa-check"></i>Update
          </button>

          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from "@/components/GoogleMap.vue";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
// import ImageCrop from "@/components/ImageCrop";

import {
  GET_B_LOCATION_DATA,
  UPDATE_B_LOCATION
} from "@/core/services/store/actions.type";
import Editor from "@tinymce/tinymce-vue";
import { mapGetters } from "vuex";
export default {
  components: {
    GoogleMap,
    supporteditor: Editor
  },
  data() {
    return {
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Locations",
          route: "client.location"
        },
        {
          id: 3,
          title: "Update Location",
          route: ""
        }
      ],
      formData: {
        name: "",
        description: "",
        address: "",
        city_name: "",
        zipcode: "",
        long: "",
        lat: "",
        client_timezone: "",
        is_active: false,
        enable_on_site_payment: false,
        address2: "",
        state: "",
        notification_email: "",
        display_name: "",
        phone_number: "",
        show_contact: "",
        fav_icon: "",
        payment_method: "",
        show_store_forward: false,
        card_scan: false,
        county: "",
        is_mobile_fingerprinting: false,
        photo_enabled: false,
        support_info: ""
      },
      timeZones: [],
      is_active: true,
      showImage: false,
      image_base64: "",
      loading_data: true,
      errors: [],
      image_place: "",
      image_icon: "",
      slug: "",
      image_crop_base64: "",
      showIcon: false,
      fav_image_base64: "",
      supporteditor: Editor
    };
  },
  computed: {
    ...mapGetters([
      "getAspectheight",
      "getAspectwidth",
      "getCanvasheight",
      "getCanvasheight",
      "currentUser"
    ])
  },
  created() {
    this.timeZones = this.$moment.tz.names();
    if (this.$route.name == "admin.serviceprovider.edit") {
      this.breadCrumbs[1].title = "Service Provider";
      this.breadCrumbs[1].route = "admin.serviceprovider.index";
      this.breadCrumbs[2].title = "Update Service Provider";
      this.breadCrumbs[2].route = "";
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getSingleLocation(this.$route.params.id);
  },
  methods: {
    setFullAddress(data) {
      // console.log(data);
      this.formData.address = data;
    },
    changeImage(val) {
      this.image_crop_base64 = val;
    },
    setLocationDragged(location) {
      this.formData.long = location.lng;
      this.formData.lat = location.lat;
    },
    setLocation(location) {
      this.formData.long = location.lng;
      this.formData.lat = location.lat;
      this.formData.city_name = location.city.long_name;
      this.formData.address = location.formatted_address;
      this.formData.zipcode = location.postal_code.long_name;
      let state = location.state.short_name.toUpperCase();

      // let state = this._.find(this.states, o => {
      //   return o.code.toUpperCase() == location.state.short_name.toUpperCase();
      // });

      // let country = this._.find(this.countries, (o) => { return o.code.toUpperCase() == location.country.short_name.toUpperCase() });

      // console.log(this._.find(this.states, (o) => { return o.code.toUpperCase() == location.state.short_name.toUpperCase() }), location.state.short_name.toUpperCase())
      // this.formData.country = country === undefined ? '' : country ;
      this.formData.state = state === undefined ? "" : state;
    },

    goBack() {
      if (this.$route.name == "admin.serviceprovider.edit") {
        this.$router.push({
          name: "admin.serviceprovider.index"
        });
      } else {
        var client_slug = this.$route.params.client_slug;

        this.$router.push({
          name: "client.location",
          params: { client_slug: client_slug }
        });
      }
    },
    getSingleLocation(id) {
      this.$store.dispatch(GET_B_LOCATION_DATA, id).then(data => {
        if (data.length == 0) {
          if (this.$route.name == "admin.serviceprovider.edit") {
            this.$router.push({
              name: "admin.serviceprovider.index"
            });
          } else {
            var client_slug = this.$route.params.client_slug;

            this.$router.push({
              name: "client.location",
              params: { client_slug: client_slug }
            });
          }
        }
        this.formData.name = data.name;
        this.formData.description = data.description;
        this.formData.address = data.address;
        this.formData.city_name = data.city_name;
        this.formData.zipcode = data.zipcode;
        this.formData.long = data.long;
        this.formData.lat = data.lat;
        this.formData.phone_number = data.phone_number;
        this.formData.show_contact = data.show_contact;
        this.formData.support_info = data.support_info;
        this.formData.payment_method =
          data.payment_method == null ? "" : data.payment_method;

        this.$refs.map.defaultMarkerPosition(
          Number(data.lat),
          Number(data.long)
        );
        this.formData.client_timezone = data.client_timezone;
        this.image_place = data.image;
        this.image_icon = data.fav_icon;
        this.formData.is_active = data.is_active;
        (this.formData.show_store_forward = data.show_store_forward),
          // this.formData.slug = data.slug;
          (this.slug = data.slug);
        this.formData.address2 = data.address2;
        this.formData.state = data.state;
        this.formData.enable_on_site_payment = data.enable_on_site_payment;
        this.formData.is_mobile_fingerprinting = data.is_mobile_fingerprinting;
        this.formData.card_scan = data.card_scan;
        this.formData.notification_email = data.notification_email;
        this.formData.photo_enabled = data.photo_enabled;
        this.formData.county = data.county;
        this.formData.display_name = data.display_name;
      });
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    clearForm() {
      this.service_table_data = [];
      this.formData.name = "";
      this.formData.description = "";
      this.formData.address = "";
      this.formData.city_name = "";
      this.formData.zipcode = "";
      this.formData.long = "";
      this.formData.lat = "";
      this.formData.client_timezone = "";
      this.formData.image = "";
      this.formData.slug = "";
      this.formData.notification_email = "";
      this.formData.display_name = "";
      this.formData.address2 = "";
      this.formData.state = "";
      this.formData.fav_icon = "";
      this.is_active = true;
      this.showImage = false;
      this.image_base64 = "";
      this.formData.enable_on_site_payment = false;
      this.formData.is_mobile_fingerprinting = false;
      this.formData.card_scan = false;
      this.formData.photo_enabled = false;
      this.formData.county = "";
      this.formData.support_info = "";
      this.payment_method = "";
      this.errors = [];
    },

    update(ref) {
      this.formData.notification_email =
        this.formData.notification_email != ""
          ? this.formData.notification_email.replace(" ", "")
          : "";
      this.formData.image =
        this.image_base64 != "" ? this.image_base64 : "none";
      this.formData.fav_icon =
        this.fav_image_base64 != "" ? this.fav_image_base64 : "none";

      this.loadingButton(ref);
      this.$store
        .dispatch(UPDATE_B_LOCATION, {
          id: this.$route.params.id,
          data: this.formData
        })
        .then(data => {
          this.$toastr.s(data.msg);
          this.closeBtnLoad(ref);
          if (this.$route.name == "admin.serviceprovider.edit") {
            this.$router.push({
              name: "admin.serviceprovider.index"
            });
          } else {
            var client_slug = this.$route.params.client_slug;

            this.$router.push({
              name: "client.location",
              params: { client_slug: client_slug }
            });
          }
        })
        .catch(err => {
          this.errors = err;
          this.closeBtnLoad(ref);
        });
    },
    updateCanvasImage(e, val) {
      if (val == 1) {
        this.showImage = true;
      } else {
        this.showIcon = true;
      }
      var self = this;

      var reader,
        files = e.target.files;

      reader = new FileReader();
      // var ev = event || window.event;
      reader.onload = e => {
        this.e = e;
        var img = new Image();
        img.onload = function() {
          self.drawCanvasImage(img, val);
        };

        img.src = e.target.result;
      };
      if (val == 1) {
        this.image_place = "";
      } else {
        this.image_icon = "";
      }
      reader.readAsDataURL(files[0]);
    },

    drawCanvasImage(imageObj, val) {
      var canvas = "";
      if (val == 1) {
        canvas = this.$refs.imageCanvas;
      } else {
        canvas = this.$refs.iconcanvas;
      }
      canvas.width = imageObj.width ;
      canvas.height = imageObj.height;

      var context = canvas.getContext("2d");
      // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      context.drawImage(
        imageObj,
        0,
        0
      );

      // var canvas = document.getElementById("imageCanvas");
      var base64 = canvas.toDataURL("image/png");
      if (val == 1) {
        this.image_base64 = base64;
      } else {
        this.fav_image_base64 = base64;
      }
      // const canvas = document.createElement("canvas");

      // canvas.width = imageObj.width;
      // canvas.height = imageObj.height;

      // var context = canvas.getContext("2d");
      // // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      // context.drawImage(imageObj, 0, 0);

      // // var canvas = document.getElementById("imageCanvas");
      // var base64 = canvas.toDataURL("image/png");
      // this.image_base64 = base64;
    }
  }
};
</script>

<style></style>
