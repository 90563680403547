var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-10"},[_c('div',{staticClass:"form-group",staticStyle:{"margin-bottom":"-19px !important"}},[_c('label',[_vm._v("Search and add a pin")]),_c('div',{staticClass:"input-group"},[_c('gmap-autocomplete',{staticClass:"form-control",on:{"place_changed":_vm.setPlace}}),_c('div',{staticClass:"input-group-prepend"},[_c('button',{staticClass:"btn btn-default",attrs:{"type":"button"},on:{"click":_vm.addMarker}},[_vm._v(" Search! ")])])],1)]),_c('br'),_c('gmap-map',{directives:[{name:"show",rawName:"v-show",value:(_vm.show_map),expression:"show_map"}],ref:"mapRef",staticStyle:{"width":"100%","height":"236px"},attrs:{"options":{
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false
    },"center":_vm.center,"zoom":15}},[_c('gmap-marker',{attrs:{"position":_vm.markers.position,"draggable":true},on:{"dragend":_vm.myNewAddress,"click":function($event){_vm.center = _vm.m.position}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }