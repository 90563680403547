<template>
  <div class="mb-10">
    <div class="form-group " style="margin-bottom:-19px !important;">
      <label>Search and add a pin</label>
      <div class="input-group">
        <gmap-autocomplete class="form-control" @place_changed="setPlace">
        </gmap-autocomplete>
        <div class="input-group-prepend">
          <button class="btn btn-default" type="button" @click="addMarker">
            Search!
          </button>
        </div>
      </div>
    </div>

    <br />
    <gmap-map
      v-show="show_map"
      ref="mapRef"
      :options="{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUi: false
      }"
      :center="center"
      :zoom="15"
      style="width:100%;  height: 236px;"
    >
      <gmap-marker
        :position="markers.position"
        :draggable="true"
        @dragend="myNewAddress"
        @click="center = m.position"
      ></gmap-marker>
    </gmap-map>
  </div>
</template>

<script>
export default {
  name: "GoogleMap",
  data() {
    return {
      // default to montreal to keep it simple
      // change this to whatever makes sense
      center: {},
      markers: {
        position: {
          lat: 0,
          lng: 0
        }
      },
      places: [],
      currentPlace: null,
      test: ""
      // full_address: ''
    };
  },
  mounted() {
    // console.log(this.lat,this.lng,'fghjtyu' );
    // this.$refs.mapRef.$mapPromise.then((map) => {
    // 	map.panTo({lat: this.lat, lng: this.lng})
    //   })
    // this.markers = {position :{lat: this.lat, lng: this.lng}};
    // this.center = {lat: 27.7218, lng: 85.3386};
  },
  props: {
    lat: {
      // type : String,
      default: null
    },
    lng: {
      // type : String,
      default: null
    },
    full_address: {
      default: null
    },
    show_map: {
      default: true
    }
  },

  created() {
    this.geolocate();
  },

  methods: {
    defaultMarkerPosition(lat, lng) {
      this.$refs.mapRef.$mapPromise.then(map => {
        map.panTo({ lat: Number(lat), lng: Number(lng) });
      });
      let marker = {
        lat: lat,
        lng: lng
      };
      // this.markers = { position: { lat: Number(lat), lng: Number(lng) } };

      this.markers = { position: marker };
    },
    setPlace(place) {
      this.currentPlace = place;
      this.addMarker();
    },

    myNewAddress(data) {
      this.center.lat = data.latLng.lat();
      this.center.lng = data.latLng.lng();

      this.$emit("dragPointer", { lng: this.center.lng, lat: this.center.lat });
    },
    addMarker() {
      if (this.show_map) {
        // console.log(this.currentPlace.geometry,this.currentPlace, 'here');
        //find country name
        let city = {
          long_name: "",
          short_name: ""
        };
        let country = {
          long_name: "",
          short_name: ""
        };
        let postal_code = {
          long_name: "",
          short_name: ""
        };

        let state = {
          long_name: "",
          short_name: ""
        };

        let utc_offset_minutes = this.currentPlace.utc_offset_minutes;

        let formatted_address = this.currentPlace.formatted_address.split(
          ","
        )[0];
        let full_address = this.currentPlace.formatted_address;
        this.$emit("getFullAddress", full_address);
        // console.log(this.full_address)
        var i = 0;
        var b = 0;
        for (i = 0; i < this.currentPlace.address_components.length; i++) {
          for (
            b = 0;
            b < this.currentPlace.address_components[i].types.length;
            b++
          ) {
            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
            if (
              this.currentPlace.address_components[i].types[b] == "postal_code"
            ) {
              //this is the object you are looking for
              postal_code = this.currentPlace.address_components[i];
              break;
            }
          }
        }

        i = 0;
        b = 0;

        for (i = 0; i < this.currentPlace.address_components.length; i++) {
          for (
            b = 0;
            b < this.currentPlace.address_components[i].types.length;
            b++
          ) {
            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
            if (
              this.currentPlace.address_components[i].types[b] == "locality"
            ) {
              //this is the object you are looking for
              city = this.currentPlace.address_components[i];
              break;
            }
          }
        }

        i = 0;
        b = 0;
        for (i = 0; i < this.currentPlace.address_components.length; i++) {
          for (
            b = 0;
            b < this.currentPlace.address_components[i].types.length;
            b++
          ) {
            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
            if (this.currentPlace.address_components[i].types[b] == "country") {
              //this is the object you are looking for
              country = this.currentPlace.address_components[i];
              break;
            }
          }
        }

        i = 0;
        b = 0;
        for (i = 0; i < this.currentPlace.address_components.length; i++) {
          for (
            b = 0;
            b < this.currentPlace.address_components[i].types.length;
            b++
          ) {
            //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
            if (
              this.currentPlace.address_components[i].types[b] ==
              "administrative_area_level_1"
            ) {
              //this is the object you are looking for
              state = this.currentPlace.address_components[i];
              break;
            }
          }
        }

        let marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
          city: city,
          country: country,
          postal_code: postal_code,
          state: state
        };
        let data_to_send = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
          city,
          country,
          postal_code,
          formatted_address,
          state,
          utc_offset_minutes
        };

        this.markers = { position: marker };
        this.places.push(this.currentPlace);
        this.$emit("onSearchClick", data_to_send);
        this.center = marker;
      }
    },
    geolocate: function() {
      navigator.geolocation.getCurrentPosition(position => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
      });
    }
  }
};
</script>
